<template>
  <v-container class="pt-12" fluid>
    <v-row>
      <v-col cols="12" md="6">
        <h1>Posts</h1>
      </v-col>
      <v-col cols="auto" class="ml-auto">
        <v-btn tile color="indigo" dark v-if="isAdmin" @click="newPost">
          작성하기
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="py-5"></v-divider>
    <v-row justify="space-between">
      <v-col cols="3" class="d-none d-md-block">
        <v-card class="pa-2" color="error" dark>
          <v-container>
            <v-row>
              <v-col cols="4" class="text-center">
                <v-icon size="90" dark>mdi-alert</v-icon>
              </v-col>
              <v-col class="pa-0">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" class="py-2 px-0">
                      <h1 class="text-h2">{{ pCntNotice }}</h1>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <h3>Notice Post</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-card class="pa-2" color="indigo" dark>
          <v-container>
            <v-row>
              <v-col cols="4" class="text-center">
                <v-icon size="90" dark>mdi-bullhorn</v-icon>
              </v-col>
              <v-col class="pa-0">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" class="py-2 px-0">
                      <h1 class="text-h2">{{ pCntAnnouncement }}</h1>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <h3>Announcement Post</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-card class="pa-2" color="warning" dark>
          <v-container>
            <v-row>
              <v-col cols="4" class="text-center">
                <v-icon size="90" dark>mdi-school</v-icon>
              </v-col>
              <v-col class="pa-0">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" class="py-2 px-0">
                      <h1 class="text-h2">{{ pCntKnowledgeBase }}</h1>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <h3>Knowledge Base</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-card class="pa-2" color="info" dark>
          <v-container>
            <v-row>
              <v-col cols="4" class="text-center">
                <v-icon size="90" dark>mdi-post</v-icon>
              </v-col>
              <v-col class="pa-0">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" class="py-2 px-0">
                      <h1 class="text-h2">{{ pCntArticle }}</h1>
                    </v-col>
                    <v-col cols="12" class="py-1">
                      <h3>Article Post</h3>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card tile elevation="3" class="pa-0">
          <v-card-text class="pa-0 py-3">
            <v-container fluid>
              <v-row justify-md="end">
                <v-col cols="12" md="2">
                  <v-select
                    dense
                    hide-details="auto"
                    outlined
                    label="Read Type"
                    :items="searchFilter.read_types"
                    v-model="searchFilter.read_type"
                    @input="getPosts"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    dense
                    hide-details="auto"
                    outlined
                    label="Post Type"
                    :items="searchFilter.post_types"
                    v-model="searchFilter.post_type"
                    @input="getPosts"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    dense
                    hide-details="auto"
                    outlined
                    label="Search Option"
                    :items="searchFilter.search_options"
                    v-model="searchFilter.search_option"
                    @input="getPosts"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    dense
                    hide-details="auto"
                    outlined
                    label="Search Keyword"
                    v-model="searchFilter.keywords"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto">
                  <v-btn color="#FF4D00" dark tile @click="getPosts">
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          class="elevation-3"
          :headers="headers"
          :items="posts"
          @click:row="postClick"
        >
          <template v-slot:item.createdAt="{ item }">
            {{ fidDatetime(item.createdAt) }}
          </template>
          <template v-slot:item.post_type="{ item }">
            <v-chip :color="getPostTypeColor(item.post_type)" dark>{{
              item.post_type
            }}</v-chip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getPosts, getPostCounts } from "@/api/Post";
import moment from "moment";
export default {
  mounted() {
    this.getPostCounts();
    this.getPosts();
  },
  methods: {
    postClick(post) {
      this.$router.push(`/posts/view/${post.post_id}`);
    },
    newPost() {
      this.$router.push(`/posts/Editor`);
    },
    async getPosts() {
      const squery = {};
      if (this.searchFilter.read_type)
        squery.read_type = this.searchFilter.read_type;
      if (this.searchFilter.post_type)
        squery.post_type = this.searchFilter.post_type;
      squery[this.searchFilter.search_option] = this.searchFilter.keywords;
      const data = await getPosts(squery);
      if (data.success) {
        this.posts = data.data;
      }
    },
    async getPostCounts() {
      const data = await getPostCounts();
      if (data.success) {
        this.postCounts = data.data;
      }
    },
    fidDatetime(datetime) {
      return moment(datetime).format("yyyy-MM-DD HH:mm:ss");
    },
    getPostTypeColor(post_type) {
      if (post_type === "Notice") return "error";
      if (post_type === "Announcement") return "indigo";
      if (post_type === "KnowledgeBase") return "warning";
      if (post_type === "Article") return "info";
    },
  },
  computed: {
    myInfo() {
      return JSON.parse(this.$store.getters["userInfo/userData"]);
    },
    isAdmin() {
      return this.myInfo.user_level > 3;
    },
    pCntNotice() {
      const counts = this.postCounts.find((e) => e.post_type == "Notice");
      if (counts) {
        return counts.counts;
      }
      return 0;
    },
    pCntAnnouncement() {
      const counts = this.postCounts.find((e) => e.post_type == "Announcement");
      if (counts) {
        return counts.counts;
      }
      return 0;
    },
    pCntKnowledgeBase() {
      const counts = this.postCounts.find(
        (e) => e.post_type == "KnowledgeBase"
      );
      if (counts) {
        return counts.counts;
      }
      return 0;
    },
    pCntArticle() {
      const counts = this.postCounts.find((e) => e.post_type == "Article");
      if (counts) {
        return counts.counts;
      }
      return 0;
    },
  },
  data() {
    return {
      headers: [
        { text: "Post ID", align: "start", width: 10, value: "post_id" },
        { text: "Post Type", align: "start", width: 10, value: "post_type" },
        { text: "Title", align: "start", width: 300, value: "post_title" },
        // { text: "Writer", align: "start", width: 10, value: "post_writer" },
        {
          text: "Write Datetime",
          align: "start",
          width: 10,
          value: "createdAt",
        },
      ],
      posts: [],
      postCounts: [],
      searchFilter: {
        post_types: [
          { text: "All", value: null },
          { text: "Notice", value: "Notice" },
          { text: "Announcement", value: "Announcement" },
          { text: "KnowledgeBase", value: "KnowledgeBase" },
          { text: "Article", value: "Article" },
        ],
        post_type: null,
        read_types: [
          { text: "All", value: null },
          { text: "Read", value: "Read" },
          { text: "UnRead", value: "UnRead" },
        ],
        read_type: null,
        search_options: [
          { text: "Title", value: "post_title" },
          { text: "Content", value: "post_content" },
        ],
        search_option: "post_title",
        keywords: "",
      },
    };
  },
};
</script>

<style lang="css" scoped>
div.v-data-table >>> tbody tr :hover {
  cursor: pointer !important;
}
</style>
